@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.1em;
  color: #000;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .inner {
    padding: 64px 0 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 100px;
}

@media only screen and (max-width: 640px) {
  #contents_wrap {
    margin-bottom: 50px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 830px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 831px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
  margin-bottom: 0 !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  max-width: 100% !important;
  float: none;
  margin-right: 0;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  font-weight: bold;
  background: #fdeff2;
}

@media only screen and (max-width: 640px) {
  header#global_header {
    padding-top: 48px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  width: 100%;
  padding-top: 20px;
}

@media only screen and (max-width: 640px) {
  #header {
    padding-top: 0;
  }
}

#header #siteID {
  width: 54%;
  float: left;
  text-align: right;
  margin-bottom: 15px;
}

@media only screen and (max-width: 830px) {
  #header #siteID {
    padding: 10px 0 !important;
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  #header #siteID {
    padding: 10px 0 !important;
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #header #siteID {
    padding: 14px;
  }
}

#header #siteID a {
  opacity: 1;
}

#header #nav_global {
  width: 100%;
}

#header #nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 800px;
  padding: 20px 0;
}

#header #nav_global ul li {
  font-size: 18px;
  position: relative;
}

#header #nav_global ul li a {
  color: #333;
  display: block;
}

#header #nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#header #nav_global ul ul {
  position: absolute;
  width: 215px;
  z-index: 100;
  top: 100%;
  left: -90%;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#header #nav_global ul ul li {
  position: relative;
}

#header #nav_global ul ul li a {
  padding: 5px 0;
  display: block;
}

#header #hed_info {
  float: right;
  width: 27%;
  text-align: right;
}

@media only screen and (max-width: 830px) {
  #header #hed_info {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  #header #hed_info {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  #header #hed_info {
    width: 45%;
  }
}

#header .tel2 {
  font-size: 30px;
  color: #af944c;
  line-height: 1;
}

#header .tel2 a {
  color: #af944c;
}

@media only screen and (max-width: 640px) {
  #header .tel2 {
    font-size: 24px;
    line-height: 1.5;
  }
}

#header .time {
  color: #af944c;
}

.mainArea {
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .mainwrap img {
    max-width: 140% !important;
    height: 250px !important;
    object-fit: cover;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  text-shadow: 5px 5px 10px rgba(51, 51, 51, 0.5);
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
  }
  #local-keyvisual h1 span {
    padding: 100px 0 !important;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 200px 0;
  background-color: rgba(66, 66, 66, 0.4);
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
    background-size: cover;
  }
}

.pan1 {
  margin: 20px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.mean-container .mean-bar {
  background: rgba(255, 255, 255, 0.5) !important;
  position: fixed !important;
  z-index: 8 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.mean-container .mean-nav {
  background: #fdeff2 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a {
  padding: 10px 5% !important;
  color: #af944c !important;
}

.mean-container .mean-nav ul li a i {
  margin-right: 15px;
  font-size: 16px;
  color: #af944c !important;
}

.mean-container a.meanmenu-reveal span {
  background: #af944c !important;
}

.mean-container a.meanmenu-reveal {
  color: #af944c !important;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: #fdeff2;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  background: #fdeff2;
  color: #333;
  margin-top: 0;
  font-family: source-han-serif-japanese, serif;
  font-style: normal;
  padding-top: 20px;
  text-align: center;
}

footer#global_footer a {
  color: #333;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer #nav_footer {
  margin: 20px 0;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 16px;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

img {
  margin-bottom: 20px;
}

.h2_01 h2 {
  font-size: 30px;
}

@media only screen and (max-width: 640px) {
  .h2_01 h2 {
    font-size: 23px;
  }
}

.h2_02 {
  width: 20%;
}

.h2_02 h2 {
  writing-mode: vertical-rl;
  display: block;
  font-size: 30px;
  line-height: 1.3;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 830px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
  }
  .sec_01 .flxR {
    width: 100% !important;
  }
  .sec_01 img {
    width: 100% !important;
  }
}

.sec_01 .flxL {
  width: 50%;
}

.sec_01 .flxR {
  display: flex;
  width: 47%;
}

.sec_01 .txt {
  writing-mode: vertical-rl;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  width: 38%;
}

@media only screen and (max-width: 830px) {
  .sec_01 .txt {
    width: 42%;
  }
}

.sec_01 .txt:before {
  content: url(../images/common/oogi.png);
  margin-bottom: 50px;
}

.sec_01 .btn {
  writing-mode: vertical-rl;
  font-size: 20px;
  position: relative;
  width: 16%;
}

@media only screen and (max-width: 830px) {
  .sec_01 .btn {
    width: 26%;
  }
}

.sec_01 .btn a {
  color: #333;
  display: block;
  border: 1px solid #333;
  position: absolute;
  top: 34%;
  left: 0;
  padding: 5px 13px;
}

@media only screen and (max-width: 830px) {
  .sec_01 .btn a {
    left: 25%;
  }
}

.bnrbox {
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .bnrbox {
    display: block !important;
  }
  .bnrbox .box {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.bnrbox .box {
  width: 31%;
}

.bnrbox .box > div {
  text-align: center;
  font-size: 20px;
  padding-top: 15px;
}

.bnrbox2 {
  justify-content: space-between;
  flex-wrap: wrap;
}

.bnrbox2 .box {
  width: 48%;
  margin-bottom: 50px;
}

.bnrbox3 {
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .bnrbox3 {
    display: block !important;
  }
  .bnrbox3 .box {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.bnrbox3 .box {
  width: 31%;
}

.bnrbox4 {
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .bnrbox4 {
    display: block !important;
  }
  .bnrbox4 .box {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.bnrbox4 .box {
  width: 31%;
  text-align: center;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .bnrbox4 .box {
    width: 48%;
  }
}

@media screen and (min-width: 831px) and (max-width: 1000px) {
  .bnrbox4 .box {
    width: 48%;
  }
}

.newswrap {
  background: url(../images/common/news_bg.jpg);
  padding: 25px;
}

.news {
  background: #fff;
  height: 200px;
  overflow-y: scroll;
  padding: 20px 0;
}

.news dt {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 830px) {
  .news dt {
    display: block !important;
    width: 100% !important;
  }
}

.news dd {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 830px) {
  .news dd {
    width: 100% !important;
  }
}

.news dl {
  padding: 20px !important;
}

@media only screen and (max-width: 830px) {
  .news dl {
    display: block !important;
  }
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

/* box */
.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 830px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

/* title */
.txt {
  font-size: 18px;
}

.h2_03 h2 {
  font-size: 30px;
}

@media only screen and (max-width: 640px) {
  .h2_03 h2 {
    font-size: 23px;
  }
}

.h2_03 h2:before {
  content: url(../images/common/oogiicon.png);
  vertical-align: sub;
  padding-right: 10px;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6cde3;
}

@media only screen and (max-width: 640px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
    padding: 10px;
  }
}

.sec_02 .flxL {
  width: 28%;
}

.sec_02 .flxR {
  width: 68%;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
  }
}

.sec_03 .table_01 {
  width: 51%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .table_01 {
    margin-top: 10px;
    width: 100%;
  }
}

.sec_03 .table_01 dt {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .sec_03 .table_01 dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
}

.sec_03 .table_01 dd {
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .sec_03 .table_01 dd {
    text-align: center;
    width: 100% !important;
  }
}

.sec_03 .table_01 dl {
  border-bottom: 1px solid #B0D391;
}

@media only screen and (max-width: 640px) {
  .sec_03 .table_01 dl {
    display: block !important;
  }
}

.sec_03 .img {
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .img {
    width: 100%;
  }
}

.sec_03 img {
  width: 100% !important;
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .map {
    width: 100% !important;
  }
  .sec_04 .map iframe {
    width: 100% !important;
  }
  .sec_04 .map2 {
    width: 100% !important;
  }
}

.sec_04 .map {
  width: 51%;
}

.sec_04 .map iframe {
  width: 100%;
}

.sec_04 .map2 {
  width: 48%;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .img {
    width: 100% !important;
  }
  .sec_05 .txt {
    width: 100% !important;
  }
}

.sec_05 .img {
  width: 48%;
}

.sec_05 .txt {
  width: 48%;
}

.sec_06 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .flxL {
    width: 100% !important;
  }
  .sec_06 .flxR {
    width: 100% !important;
  }
}

.sec_06 .flxL {
  width: 48%;
}

.sec_06 .flxR {
  width: 48%;
}

.sec_07 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_07 {
    display: block;
  }
  .sec_07 .flxL {
    width: 100% !important;
  }
  .sec_07 .flxR {
    width: 57% !important;
    margin: 0 auto;
  }
}

.sec_07 .flxL {
  width: 48%;
}

.sec_07 .flxR {
  width: 43%;
}

@media only screen and (max-width: 640px) {
  .sec_07 .flxR {
    margin-top: 30px;
  }
}

.sec_07 .flxR .txt span {
  font-size: 50px;
  color: #e597b2;
}

.sec_07 .flxR .txt1 span {
  font-size: 50px;
  color: #c3d825;
}

.sec_07 .flxR .txt2 span {
  font-size: 50px;
  color: #cd5e3c;
}

.sec_07 .flxR .txt3 span {
  font-size: 50px;
  color: #6c848d;
}

.event dt {
  display: block !important;
  width: 100% !important;
  padding: 0 0 30px !important;
  font-size: 22px;
  border: none !important;
}

.event dd {
  width: 100% !important;
  border: none !important;
  padding: 0 !important;
}

.event dl {
  display: block !important;
  padding: 0 0 50px 0;
}

.quewrap {
  background: #B0D391;
  padding: 50px;
}

@media only screen and (max-width: 640px) {
  .quewrap {
    padding: 20px;
  }
}

.quewrap .question > div {
  border-bottom: 1px solid #FDD9D9;
  padding: 10px 0;
  font-weight: bold;
  font-size: 20px;
}

.quewrap .answer {
  padding: 10px 0;
}

.imgbox {
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .imgbox {
    display: block !important;
  }
  .imgbox .box {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

.imgbox .box {
  width: 31%;
}

.shouhinimg {
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .shouhinimg {
    display: block !important;
  }
  .shouhinimg .box {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

.shouhinimg .box {
  width: 48%;
}

.darumaimg {
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .darumaimg {
    display: block !important;
  }
  .darumaimg .box {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

.darumaimg .box {
  width: 31%;
}

.wagashiimg {
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .wagashiimg {
    display: block !important;
  }
  .wagashiimg .box {
    width: 100% !important;
  }
}

.wagashiimg .box {
  width: 31%;
  margin-bottom: 10px;
}

.wagashiimg .box > div {
  margin: 10px 0;
}

.table_02 dt {
  border: none !important;
}

.table_02 dd {
  border: none !important;
}

.table_02 dl {
  border-bottom: 1px solid #bbc8e6;
}

@media only screen and (max-width: 640px) {
  .table_03 dl {
    display: block !important;
  }
  .table_03 dt {
    display: block !important;
    width: 100% !important;
  }
  .table_03 dd {
    width: 100% !important;
  }
}

.table_03 dt {
  border: none !important;
  padding: 10px 0 !important;
  font-size: 18px;
}

.table_03 dd {
  border: none !important;
  padding: 10px 0 !important;
}

.table_03 dl {
  border-bottom: 1px solid #e0ebaf;
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 830px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #DCD6D9;
  background-size: cover;
  z-index: -1;
}
